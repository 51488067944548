<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <v-main style="padding: 0 1rem !important;">
    <!-- filtros de busqueda y boton para agregar un contrato -->
    <div class="d-flex pt-3 ">
      <v-text-field v-model="filtroUsuario" outlined dense class="me-2" label="Usuario" hide-details>
      </v-text-field>

      <v-text-field v-model="filtroAseguradora" class="me-2" outlined dense label="Aseguradora" hide-details>
      </v-text-field>

      <v-select v-model="filtroEstado" :items="informacionEstados" outlined dense label="Estado" hide-details>
      </v-select>

      <v-tooltip left color="success">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small class="ms-2" fab color="success" v-bind="attrs" v-on="on" @click="dialogRelacion = true">
            <v-icon> add </v-icon>
          </v-btn>
        </template>
        <span>Agregar relación</span>
      </v-tooltip>
    </div>

    <!-- dialogo para crear y actualizar un contrato -->
    <v-dialog v-model="dialogRelacion" persistent transition="dialog-bottom-transition" max-width="35rem">
      <v-card>
        <v-card-title style="background-color: #1867c0 !important;color: white;">
          <span class="text-h6">Agrear relación</span>
        </v-card-title>
        <!-- formulario para editar y crear un contrato -->
        <v-card-text style="padding-top: 0.7rem;">
          <v-form class="mt-3">
            <v-autocomplete outlined dense :items="listaUsuarios" v-model="usuarioSeleccionado" return-object
              :item-text="mostrarUsuarios" @keyup="delayBuscarUsuarios($event.target.value)"
              label="Seleccione el usuario">
            </v-autocomplete>
            <v-autocomplete outlined dense label="Seleccione la aseguradora" :items="listaAseguradoras"
              v-model="aseguradoraSeleccionada" return-object :item-text="mostrarAseguradoras"
              @keyup="delayBuscarAseguradoras($event.target.value)">
            </v-autocomplete>
          </v-form>
          <div class="d-flex justify-end">
            <v-btn color="error" text @click="cerrarDialogRelacion()">
              CERRAR
            </v-btn>
            <v-btn @click="crearNuevaRelacion()" depressed color="success" text>
              Guardar
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbarVisible" :color="colorSnackbar" multi-line top :timeout="5000">
      {{ mensajeSnackar }}
      <template v-slot:action="{ attrs }">
        <v-btn color="secondary" text v-bind="attrs" @click="snackbarVisible = false">CERRAR</v-btn>
      </template>
    </v-snackbar>

    <!-- Tabla que muestra los registros del contrato -->
    <v-data-table class="elevation mt-4" height="66vh" :headers="headers" :items="listaRelaciones"
      :footer-props="footerProps" :items-per-page="itemsPorPagina" :page="pagina" :server-items-length="totalElementos"
      @update:items-per-page="(i) => itemsPorPagina = i" @update:page="(p) => pagina = p" fixed-header :loading="loading"
      loading-text="Cargando...">
      <template v-slot:footer.page-text="items">
        {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
      </template>
      <template v-slot:[`item.estado`]="{ item }">
        <div class="d-flex align-center">
          <v-icon :color="item.eliminado === true ? 'red' : 'green darken-2'">
            {{ item.eliminado === true ? 'cancel' : 'check_circle' }}
          </v-icon>
          {{ item.eliminado === true ? 'Inactivo' : 'Activo' }}
        </div>
      </template>
      <template v-slot:[`item.eliminar`]="{ item }">
        <section class="d-flex">
          <v-tooltip bottom :color="item.eliminado === false ? 'error' : 'success'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text icon :color="item.eliminado === false ? 'error' : 'success'" @click="modalActivoDesactivo(item)"
                v-bind="attrs" v-on="on">
                <v-icon>{{ item.eliminado === false ? 'disabled_by_default' : 'fact_check' }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.eliminado === false ? 'Inactivar' : 'Activar' }}</span>
          </v-tooltip>
        </section>
      </template>
      <!-- Botón para ver la trazabilidad -->
      <template v-slot:[`item.trazabilidad`]="{ item }">
        <v-tooltip bottom color="blue">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text icon color="blue" v-bind="attrs" v-on="on" @click="listarTrazabilidad(item)">
              <v-icon>backup_table</v-icon></v-btn>
          </template>
          <span>Trazabilidad</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogEstado" max-width="360">
      <v-card>
        <v-card-title style="background-color: #1867c0 !important;color: white;">
          {{
            filaSeleccionada.eliminado === false ? '¿Desea desactivar la relación?' : '¿Desea activar la relación?'
          }}
        </v-card-title>
        <div class="d-flex justify-end pa-3">
          <v-btn color="success" @click="actualizarEstadoRelacion(filaSeleccionada, estadoEditado)"> Si </v-btn>
          <v-btn color="error" text @click="dialogEstado = false"> No </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <ModalTrazabilidad :trazabilidadData="trazabilidadData" :trazaDe="trazaDe" />
  </v-main>
</template>	 	 
<!-- #################################################################################### -->
<!-- ###### Sección de scripts ###### -->
<!-- #################################################################################### -->	 
<script>

import { mapMutations, mapState } from "vuex";
import ModalTrazabilidad from '@/components/ModalTrazabilidad.vue';

const debounce = function debounce(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

export default {
  name: "UsuariosAseguradoras",
  components: { ModalTrazabilidad },

  data() {
    return {
      dialogRelacion: false,
      dialogEstado: false,
      estadoEditado: false,
      itemsPorPagina: 10,
      pagina: 1,
      totalElementos: 0,
      filtroUsuario: "",
      filtroAseguradora: "",
      filtroEstado: "",
      snackbarVisible: false,
      colorSnackbar: "",
      mensajeSnackar: "",
      tiempoSnackbar: 5000,
      loading: false,
      usuarioSeleccionado: {},
      aseguradoraSeleccionada: {},
      filaSeleccionada: {},
      listaUsuarios: [],
      listaAseguradoras: [],
      listaRelaciones: [],
      trazabilidadData:[],
      trazaDe: "",
      informacionEstados: [
        { text: "Activo", value: "false" },
        { text: "Inactivo", value: "true" },
        { text: "Todos", value: "null" }
      ],
      footerProps: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Items por página:',
        'show-current-page': true,
        'show-first-last-page': true,
      },
      headers: [
        { text: "USUARIO", value: "nombreUsuario", align: "center", width: "20%", sortable: false },
        { text: "ASEGURADORA", value: "nombreAseguradora", sortable: false, width: "40%", align: "center" },
        { text: "ESTADO", value: "estado", sortable: false, width: "20%" },
        { text: "ACCIONES", value: "eliminar", align: "center", sortable: false, width: "10%" },
        { text: "TRAZABILIDAD", value: "trazabilidad", align: "center", sortable: false, }
      ],
    }
  },

  created() {
    this.buscarRelaciones()
  },

  computed: {
    ...mapState(["auth", "notify", "busy", "trazabilidad", "enterprise"]),
  },

  methods: {

    ...mapMutations([
      "showSuccess",
      "showError"
    ]),

    buscarRelaciones() {
      this.listaRelaciones = [];
      this.loading = true;
      this.$http.get("/msa-administration/usuarioAseguradora/listarRelaciones", {
        params: {
          page: this.pagina - 1,
          size: this.itemsPorPagina,
          usuario: this.filtroUsuario,
          aseguradora: this.filtroAseguradora,
          estado: this.filtroEstado
        }
      }).then((result) => {
        this.loading = false;
        this.listaRelaciones = result.data.content;
        this.totalElementos = result.data.totalElements
        console.log(result.data)
      }).catch(() => {
        this.loading = false;
      })
    },

    delayBuscarUsuarios: debounce(function (param) {
      this.buscarUsuarios(param)
    }, 500),

    buscarUsuarios(usuario) {
      this.$http.get("/msa-administration/usuarioAseguradora/listarUsuarios", {
        params: {
          usuario: usuario
        }
      }).then((result) => {
        this.listaUsuarios = result.data;
        console.log(this.listaUsuarios)
      })
    },

    mostrarUsuarios(item) {
      return item.usuario;
    },

    delayBuscarAseguradoras: debounce(function (param) {
      this.buscarAseguradoras(param)
    }, 500),

    buscarAseguradoras(aseguradora) {
      this.$http.get("/msa-administration/usuarioAseguradora/listarAseguradoras", {
        params: {
          aseguradora: aseguradora
        }
      }).then((result) => {
        this.listaAseguradoras = result.data;
        console.log(this.listaAseguradoras)
      })
    },

    mostrarAseguradoras(item) {
      return item.nombre_aseguradora;
    },

    modalActivoDesactivo(item) {
      this.dialogEstado = true;
      this.filaSeleccionada = item;
      item.eliminado === false ? this.estadoEditado = true : this.estadoEditado = false;
    },

    actualizarEstadoRelacion(filaSeleccionada, estadoEditado) {
      this.$http.put("/msa-administration/usuarioAseguradora/editarEstado", {
        idUsas: filaSeleccionada.idPrincipal,
        eliminado: estadoEditado
      }).then((result) => {
        if (result.data.response == true) {
          console.log(result.data)
          this.buscarRelaciones();
          this.dialogEstado = false;
          this.snackbarVisible = true;
          this.mensajeSnackar = result.data.mensaje;
          this.colorSnackbar = "green"
          this.tiempoSnackbar = 5000
        } else {
          console.log(result.data)
          this.buscarRelaciones();
          this.dialogEstado = true;
          this.snackbarVisible = true;
          this.mensajeSnackar = result.data.mensaje;
          this.colorSnackbar = "red"
          this.tiempoSnackbar = 5000
        }
      }).catch(() => {
        this.dialogEstado = false;
        this.snackbarVisible = true;
        this.mensajeSnackar = "Ocurrio un error inesperado al realizar el cambio de estado";
        this.colorSnackbar = "red"
        this.tiempoSnackbar = 5000
      })
    },

    cerrarDialogRelacion() {
      this.dialogRelacion = false;
      this.usuarioSeleccionado = {},
      this.aseguradoraSeleccionada = {}
      this.listaUsuarios = [],
      this.listaAseguradoras = []
    },

    crearNuevaRelacion() {
      if (this.usuarioSeleccionado?.usuario != null && this.aseguradoraSeleccionada?.id_aseguradora != null) {
        this.$http.post("/msa-administration/usuarioAseguradora/guardarRelacion", {
          empresa: {
            idEmpresa: 1
          },
          usuarios: {
            usuario: this.usuarioSeleccionado.usuario,
          },
          aseguradora: {
            idAseguradora: this.aseguradoraSeleccionada.id_aseguradora
          },
        }).then((result) => {
          if (result.data.response == true) {
            console.log(result)
            this.dialogRelacion = false;
            this.snackbarVisible = true;
            this.mensajeSnackar = result.data.mensaje;
            this.colorSnackbar = "green";
            this.tiempoSnackbar = 5000;
            this.buscarRelaciones();
            this.cerrarDialogRelacion();
          } else {
            this.snackbarVisible = true;
            this.mensajeSnackar = result.data.mensaje;
            this.colorSnackbar = "red";
            this.tiempoSnackbar = 5000;
          }
        }).catch(() => {
          this.cerrarDialogRelacion();
          this.snackbarVisible = true;
          this.mensajeSnackar = "Ocurrio un error inesperado al guardar la relación";
          this.colorSnackbar = "red"
          this.tiempoSnackbar = 5000
        })
      } else {
        this.snackbarVisible = true;
        this.mensajeSnackar = "Debe llenar todos los campos";
        this.colorSnackbar = "red";
        this.tiempoSnackbar = 5000;
      }
    },

    listarTrazabilidad(relacion) {
      this.$http.get(`/msa-administration/usuarioAseguradora/listarTrazabilidad`, {
        params:{
          idUsas: relacion.idPrincipal
        }
      }).then(({ data }) => {
          data.forEach(element => {
            element.time = this.formatoFechaTrazabilidad(element.time);
            if (element.campo == 'eliminado') element.campo = 'Eliminado';
            if (element.anterior == true) element.anterior = 'Inactivo';
            if (element.anterior == false) element.anterior = 'Activo';
            if (element.nuevo == false) element.nuevo = 'Activo';
            if (element.nuevo == true) element.nuevo = 'Inactivo';
          })
          this.trazabilidad.visible = true;
          this.trazaDe = `${relacion.nombreUsuario} - ${relacion.nombreAseguradora}`
          this.trazabilidadData = data.reverse();
        }).catch(err => console.log(err));
    },

    formatoFechaTrazabilidad(dateTimeString) {
      const fecha = new Date(dateTimeString);
      const horas = fecha.getHours();
      const minutos = fecha.getMinutes();
      const ampm = horas >= 12 ? 'p.m.' : 'a.m.';
      const formatoHoras = horas % 12 || 12;
      const formatoMinutos = minutos < 10 ? `0${minutos}` : minutos;
      const formatoMes = (fecha.getMonth() + 1).toString().padStart(2, '0');
      const formatoDia = fecha.getDate().toString().padStart(2, '0');
      const formatoFechaHora = `${fecha.getFullYear()}/${formatoMes}/${formatoDia} ${formatoHoras.toString().padStart(2, '0')}:${formatoMinutos}${ampm}`;
      return formatoFechaHora;
    },
  },

  watch: {

    "filtroUsuario": debounce(function () {
      this.buscarRelaciones()
    }, 500),

    "filtroAseguradora": debounce(function () {
      this.buscarRelaciones()
    }, 500),

    "filtroEstado": function () {
      this.buscarRelaciones()
    },

    "pagina": function () {
      this.buscarRelaciones()
    },

    "itemsPorPagina": function () {
      this.buscarRelaciones()
    }
  },
}

</script>	 
		
<!-- #################################################################################### -->
<!-- ###### Sección de estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

::v-deep .elevation .v-data-footer {
  width: 100%;
}

::v-deep .elevation .v-data-footer__select .v-select {
  margin: 5px;
  margin-left: 10px;
}

::v-deep .elevation .v-data-table__wrapper {
  border: 1px solid #f7f6f6;
}

::v-deep #objeto {
  margin: 5px;
}
</style> 